<template>
  <app-layout>
    <div class="page">
      <img src="./images/steps.png" alt="" class="steps" />
      <div
        class="scene-introduction"
        :style="`background-image:url(${scenePicture});`"
      >
        <div class="scene-introduction-text1">{{ domainName }}</div>
        <div class="scene-introduction-text2"></div>
        <div class="scene-introduction-text3">{{ domainContent }}</div>
        <div class="software-icon-list">
          <div
            v-for="(item, index) in domains"
            class="software-icon"
            @click="clickSoftware(item, index)"
          >
            <img
              :src="item.softwareLogo"
              alt=""
              :class="
                index == currentTab
                  ? 'software-icon-img-active'
                  : 'software-icon-img'
              "
            />
            <img
              src="./images/p-81.png"
              alt=""
              :class="
                index == currentTab
                  ? 'software-icon-active'
                  : 'software-icon-unactive'
              "
            />
          </div>
        </div>
      </div>
      <div class="goods-list">
        <div
          v-for="(item, index) in productInfoPOS"
          :style="(index + 1)%4 == 0 ? '':'margin-right: 29px;'"
          :class="index == currentGoods ? 'goods-checked goods' : 'goods'"
          @click="clickGoods(item)"
          @mouseover="mouseOverGoodsList"
        >
          <img :src="item.pictures[0].img" class="goods-img" />
          <div class="goods-text-1">{{ item.productName }}</div>
          <div class="goods-text-2">{{ item.described }}</div>
	        <div style="display: flex; align-items: center; margin-top: 15px">
		        <div class="goods-text-3" style="margin-top: 0px !important">
			        性能:
		        </div>
		        <div class="schedule-bottom"></div>
		        <div
				        class="schedule-top-power"
				        :style="`width :${item.performance}rem`"
		        ></div>
	        </div>
          <div style="display: flex; align-items: center; margin-top: 5px">
            <div class="goods-text-3">算力:</div>
            <div class="schedule-bottom"></div>
            <div
              class="schedule-top-strength"
              :style="`width:${item.computingPower}rem`"
            ></div>
          </div>
          <div class="goods-price">¥{{ item.price }}</div>
        </div>
      </div>
    </div>
  </app-layout>
  <home-backup />
</template>

<script setup>
import AppLayout from "@/components/AppLayout.vue";
import HomeBackup from "@/views/home/components/HomeBackup.vue";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { domainById, listByDomainId } from "@/api/solve";
import { Workstation } from "@/api/profession";
import globalVariable from "@/global-variable";


const router = useRouter();
const route = useRoute();
const query = route.query;

let domains = ref([]); // 场景下的软件
let scenePicture = ref(); // 场景图
let domainContent = ref(); // 场景介绍
let domainName = ref(); // 场景名称
let currentTab = ref(0); // 选择的软件
const currentGoods = ref(1) // 默认选中的商品

onMounted(() => {
  // 查询场景下的软件
  listByDomainId(query.industryId).then((res) => {
    if (res.code == 200) {
      domains.value = res.data;
      getGoodsInfo(res.data[0].id);
    }
  });
  // 查询场景信息
  domainById(query.industryId).then((res) => {
    if (res.code == 200) {
	    scenePicture.value = globalVariable.ossPath + '/goods' + res.data.scenePicture;
      domainContent.value = res.data.domainContent;
      domainName.value = res.data.domainName;
    }
  });
});

let checkGoodsInfgo = ref(""); // 选中商品
let productInfoPOS = ref([]); // 商品信息
// 查询软件下的商品信息
const getGoodsInfo = (id) => {
  Workstation(id).then((res) => {
    if (res.code == 200) {
      checkGoodsInfgo.value = res.data.productInfoPOS[0];
      res.data.productInfoPOS.forEach((item, index) => {
	      if (!item.computingPower) {
		      item.computingPower = 0.35;
	      } else {
		      item.computingPower = (item.computingPower / 20) * 7;
	      }
	      if (!item.performance) {
		      item.performance = 0.35;
	      } else {
		      item.performance = (item.performance / 20) * 7;
	      }
      });
      productInfoPOS.value = res.data.productInfoPOS;
    }
  });
};

// 点击软件
const clickSoftware = (item, index) => {
  currentTab.value = index;
  getGoodsInfo(item.id);
};

// 点击商品
const clickGoods = (item) => {
	console.log(item)
  if (item.machineType == 3 || item.machineType == 5 || item.machineType == 6) {
    router.push({
      name: "detailsGoodsNew",
      query: {
        id: item.id,
        houseNo: item.configType,
      },
    });
  } else {
    router.push({
      name: "detailsGoods",
      query: {
        id: item.id,
        houseNo: item.configType,
      },
    });
  }
};

const mouseOverGoodsList = () => {
	currentGoods.value = 999
}
</script>

<style scoped lang="less">
.page {
  margin: 0 auto;
  width: 1920px;
  background-color: #f8f8f8;
  padding-top: 107px;
  .steps {
    margin: 0 auto;
    display: block;
    width: 943px;
    height: 60px;
  }
  .scene-introduction {
    margin-top: 64px !important;
    margin-bottom: 44px !important;
    margin: 0 auto;
    padding-top: 54px;
    background-size: cover;
    width: 1280px;
    height: 302px;
    .scene-introduction-text1 {
      margin-left: 63px;
      height: 35px;
      line-height: 35px;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 36px;
      color: #000000;
    }
    .scene-introduction-text2 {
      margin-top: 11px;
      margin-left: 63px;
      width: 56px;
      height: 7px;
      background: #ce1220;
    }
    .scene-introduction-text3 {
      margin-top: 16px;
      margin-left: 60px;
      width: 576px;
      height: 75px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 18px;
      color: #000000;
    }
    .software-icon-list {
      display: flex;
      margin-top: 10px;
      margin-left: 49px;
      .software-icon {
        display: flex;
        flex-direction: column;
        margin-right: 21px;
	      cursor: pointer;
        .software-icon-img-active {
	        padding: 4px;
          border: 2px solid #000000;
          border-radius: 20px;
          width: 76px;
          height: 76px;
        }
        .software-icon-img {
	        margin-top: 4px;
	        border: 1px solid #DBDBDB;
          border-radius: 20px;
          width: 68px;
          height: 68px;
        }
        .software-icon-active {
          margin-top: 3px !important;
          margin: 0 auto;
          width: 13px;
          height: 11px;
        }
        .software-icon-unactive {
          display: none;
        }
      }
    }
  }
  .goods-list {
    margin-top: 44px !important;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 1280px;
	  min-height: 200px;
	  .goods-checked{
		  border: 2px solid #000000 !important;
		  border-radius: 13px !important;
	  }
    .goods {
      margin-bottom: 43px;
      border-radius: 10px;
      width: 297.4px;
      height: 460px;
      background-color: #fff;
      cursor: pointer;
      &:hover {
        border: 2px solid #000000;
	      border-radius: 13px;
      }
      .goods-img {
	      margin-top: 24px !important;
	      margin: 0 auto;
	      display: block;
        /*border-top-right-radius: 10px;
        border-top-left-radius: 10px;*/
        width: 260px;
        height: 260px;
      }
      .goods-text-1 {
        margin-top: 18px;
        margin-left: 20px;
        width: 143px;
        height: 18px;
        line-height: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #000000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .goods-text-2 {
        margin-top: 8px;
        margin-left: 22px;
        width: 256px;
        height: 15px;
        line-height: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .goods-text-3 {
        margin-left: 20px;
        height: 14px;
        line-height: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
      }
      .schedule-bottom {
        margin-left: 8px;
        width: 113.5px;
        height: 8.3px;
        background: rgba(128, 132, 144,0.1);
        border-radius: 4px;
      }
	    .schedule-top-strength{
		    position: absolute;
		    margin-left: 60px;
		    height: 8.3px;
		    background: #7eb4e6;
		    border-radius: 4px;
	    }
      .schedule-top-power {
        position: absolute;
        margin-left: 60px;
        height: 8.3px;
        background: #ec4f4f;
        border-radius: 4px;
      }
      .goods-price {
        margin-top: 23px;
        margin-left: 22px;
        width: 90px;
        height: 18px;
        line-height: 18px;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        font-size: 24px;
        color: #000000;
      }
    }
  }
}
</style>
